<template>
  <div class="main-panel">
    <h1>Clients</h1>
    <div class="flexpanel">
      <input type="search" placeholder="search..." class="search" spellcheck="false" v-model="search" />
      <img class="action-btn" src="../assets/plus.svg" alt="" @click="$router.push({ name: 'Client' })" />
    </div>
    <div class="table">
      <table>
        <tr class="nohover">
          <th @click="changeSort('name')">
            <div>
              <img
                src="../assets/sort-arrow.svg"
                alt=""
                v-show="state.sortedColumn == 'name'"
                :class="{ rotate: state.sortDesc }"
              /><br />
              Name
            </div>
          </th>
          <th @click="changeSort('primarycontact')">
            <div>
              <img
                src="../assets/sort-arrow.svg"
                alt=""
                v-show="state.sortedColumn == 'primarycontact'"
                :class="{ rotate: state.sortDesc }"
              /><br />
              Primary Contact
            </div>
          </th>
          <th @click="changeSort('addressline1')">
            <div>
              <img
                src="../assets/sort-arrow.svg"
                alt=""
                v-show="state.sortedColumn == 'addressline1'"
                :class="{ rotate: state.sortDesc }"
              /><br />
              Address
            </div>
          </th>
          <th @click="changeSort('phone')">
            <div>
              <img
                src="../assets/sort-arrow.svg"
                alt=""
                v-show="state.sortedColumn == 'phone'"
                :class="{ rotate: state.sortDesc }"
              /><br />
              Phone
            </div>
          </th>
        </tr>
        <tr
          v-for="client in sortedClients"
          :key="client.clientid"
          @click="$router.push({ name: 'Client', params: { id: client.clientid } })"
        >
          <td>{{ client.name }}</td>
          <td>{{ client.primarycontact }}</td>
          <td>
            {{ client.addressline1 ? `${client.addressline1}, ${client.city}, ${client.state} ${client.zip}` : '' }}
          </td>
          <td>{{ formatPhone(client.phone) }}</td>
        </tr>
        <tr v-if="sortedClients.length == 0" class="nohover">
          <td colspan="4">No Clients Found</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import { ref, watch } from 'vue';
import clients from '../composables/clients';

export default {
  setup() {
    clients.getClients();
    const search = ref('');

    watch(search, () => {
      clients.updateSearch(search.value);
    });

    return { sortedClients: clients.sortedClients, search, state: clients.state, changeSort: clients.changeSort };
  },
  methods: {
    formatPhone(str) {
      const cleaned = ('' + str).replace(/\D/g, '');
      const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        return '(' + match[1] + ') ' + match[2] + '-' + match[3];
      }
      return str;
    },
  },
};
</script>

<style lang="scss" scoped>
.flexpanel {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.table {
  padding-top: 25px;
  table {
    width: 100%;
    border-collapse: collapse;
    tr:hover {
      background: #575757;
      cursor: pointer;
    }
    tr.nohover:hover {
      background: #3a3a3a;
      cursor: default;
    }
    th {
      border-bottom: 1px solid #dfdfdf;
      text-align: left;

      div {
        display: inline-block;
        text-align: left;
        font-size: 0.6em;
        padding-left: 8px;
        text-align: center;
        img {
          width: 9px;
        }
        .rotate {
          transform: rotate(180deg);
        }
      }
    }
    td {
      padding: 6px 8px;
    }
  }
}
</style>
